import React from 'react';
import './Main.css';
import { FirebaseContextConsumer } from '../../../../components/Firebase/context';
import LoginJoin from '../LoginJoin/LoginJoin';
import mainImg from '../../../../assets/images/LandingPage/soccer_field.png';
import leftLeafs from '../../../../assets/images/LandingPage/left_landing_leafs.png';
import rightLeafs from '../../../../assets/images/LandingPage/right_landing_leafs.png';
import bganim from './web_animation.mp4';
import hangingPlantImg from '../../../../assets/images/LandingPage/hanging-plant.png';

type MainProps = {
    hideToolbar: any;
    apiURL: string;
};

type MainState = {
    state: string;
};

export default class Main extends React.Component<MainProps, MainState> {
    constructor(props: MainProps) {
        super(props);
        this.state = {
            state: 'good',
        };
    }
    render() {
        return (
            <>
                <div className="plant">
                    <img
                        id="hanging-plant"
                        src={hangingPlantImg}
                        alt="plant"
                    ></img>{' '}
                </div>
                <div className="main-container">
                    <div className="main">
                        <div className="text">
                            <p style={{ color: 'black' }} className="top-text">
                                Hack@Brown presents:
                            </p>
                            <h1 style={{ color: 'black' }} className="big-text">
                                Greenhouse
                            </h1>
                            <p
                                style={{ color: 'black' }}
                                className="bottom-text"
                            >
                                January 29 - 30, 2022
                            </p>
                            <div
                                className="main-button"
                                style={{ alignItems: 'center' }}
                            >
                                <FirebaseContextConsumer>
                                    {(firebase) => (
                                        <LoginJoin
                                            apiURL={this.props.apiURL}
                                            firebase={
                                                firebase == null
                                                    ? null
                                                    : firebase.firebase
                                            }
                                            hideToolbar={this.props.hideToolbar}
                                        />
                                    )}
                                </FirebaseContextConsumer>
                            </div>
                        </div>
                        <div className="images">
                            <div className="left-leaf">
                                <img
                                    className="resize"
                                    src={leftLeafs}
                                    alt="left leaf"
                                ></img>
                            </div>
                            <div className="right-leaf">
                                <img
                                    className="resize resize-large"
                                    src={rightLeafs}
                                    alt="right leaf"
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
