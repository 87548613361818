import React from "react";
import "./Sponsors.css";
import placeholderImg from "../../../../assets/images/LandingPage/placeholder2.png";
import sideFernsImg from "../../../../assets/images/LandingPage/sideferns.png";
import leftFlowerImg from "../../../../assets/images/LandingPage/flower-1 2.png";

import neevaImg from "../../../../assets/images/LandingPage/companylogo/neeva.png";
import orchardImg from "../../../../assets/images/LandingPage/companylogo/orchard.png";
import pangeaImg from "../../../../assets/images/LandingPage/companylogo/pangea.png";
import palantirImg from "../../../../assets/images/LandingPage/companylogo/palantir.png";
import scmImg from "../../../../assets/images/LandingPage/companylogo/scm.svg";
import sketchImg from "../../../../assets/images/LandingPage/companylogo/sketch.png";
import reliableImg from "../../../../assets/images/LandingPage/companylogo/reliable.gif";
import wolframImg from "../../../../assets/images/LandingPage/companylogo/wolfram.png";
import googleCloudImg from "../../../../assets/images/LandingPage/companylogo/googleCloud.png";
import blueSocialImg from "../../../../assets/images/LandingPage/companylogo/bluesocial.png";
import mouserImg from "../../../../assets/images/LandingPage/companylogo/Mouser.jpg";
import verkadaImg from "../../../../assets/images/LandingPage/companylogo/verkada.png";
import metaImg from "../../../../assets/images/LandingPage/companylogo/meta.png";
import nelsonImg from "../../../../assets/images/LandingPage/companylogo/nelson.png";
type SponsorsProps = {};

type SponsorsState = {
  state: string;
};

export default class Sponsors extends React.Component<
  SponsorsProps,
  SponsorsState
> {
  constructor(props: SponsorsProps) {
    super(props);
    this.state = {
      state: "good",
    };
  }
  render() {
    return (
      <div className="sponsors">
        <div className="relative">
          <img
            src={leftFlowerImg}
            alt="left flower"
            className="left-flower"
          ></img>
        </div>
        <div className="sponsors-heading">
          <h1>Sponsors</h1>
        </div>
        <div className="sponsors-items">
          {/* <div className="sponsors-peta">
                        <h2 className="sponsors-name">Peta</h2>
                        <div className="sponsors-img">
                            <img className="sponsors-img-peta" src={placeholderImg}></img>
                            <img className="sponsors-img-peta" src={placeholderImg}></img>
                            <img className="sponsors-img-peta" src={placeholderImg}></img>
                            <img className="sponsors-img-peta" src={placeholderImg}></img>
                        </div>
                    </div>
                    <div className="sponsors-tera">
                        <h2 className="sponsors-name">Tera</h2>
                        <div className="sponsors-img">
                            <img className="sponsors-img-tera" src={placeholderImg}></img>
                            <img className="sponsors-img-tera" src={placeholderImg}></img>
                            <img className="sponsors-img-tera" src={placeholderImg}></img>
                            <img className="sponsors-img-tera" src={placeholderImg}></img>
                        </div>
                    </div> */}
          <div className="sponsors-giga sponsors-section">
            <h2 className="sponsors-name">Giga</h2>
            <div className="sponsors-img">
              <a
                href="https://about.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-giga"
                  src={metaImg}
                  alt="meta"
                  style={{ width: "20%" }}
                ></img>
              </a>
            </div>
          </div>
          <div className="sponsors-mega sponsors-section">
            <h2 className="sponsors-name">Mega</h2>
            <div className="sponsors-img">
              <a
                href="https://neeva.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-kilo"
                  src={neevaImg}
                  alt="neeva"
                  style={{ width: "20%" }}
                ></img>
              </a>
              <a
                href="https://orchard.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-kilo"
                  src={orchardImg}
                  alt="orchard"
                ></img>
              </a>
            </div>
          </div>
          <div className="sponsors-kilo sponsors-section">
            <h2 className="sponsors-name">Kilo</h2>
            <div className="sponsors-img">
              <a
                href="https://www.scm-lp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-kilo"
                  src={scmImg}
                  alt="stevens capital management"
                ></img>
              </a>
              <a
                href="https://www.about.pangea.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-kilo"
                  src={pangeaImg}
                  alt="pangea"
                  style={{ width: "20%" }}
                ></img>
              </a>
              <a
                href="https://www.palantir.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-kilo"
                  src={palantirImg}
                  alt="palantir"
                  style={{ width: "15%" }}
                ></img>
              </a>
              <a
                href="https://reliable.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-kilo"
                  src={reliableImg}
                  alt="reliable robotics"
                  style={{ borderRadius: "5px" }}
                ></img>
              </a>
              <a
                href="https://www.verkada.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-kilo"
                  src={verkadaImg}
                  alt="verkada"
                ></img>
              </a>
            </div>
          </div>
          <div className="sponsors-micro sponsors-section">
            <h2 className="sponsors-name">Micro</h2>
            <div className="sponsors-img">
              <a
                href="https://entrepreneurship.brown.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-img-micro"
                  //   src={bloombergImg}
                  src={nelsonImg}
                  //   alt="bloomberg"
                  alt="placeholder"
                ></img>
              </a>
            </div>
          </div>
          <div className="sponsors-partners sponsors-section">
            <p>In partnership with</p>
            <a
              href="https://cloud.google.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="sponsors-img-micro"
                style={{
                  width: "15%",
                }}
                src={googleCloudImg}
                alt="google cloud"
              ></img>
            </a>
            <a
              href="https://www.wolfram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="sponsors-img-micro"
                style={{
                  width: "15%",
                }}
                src={wolframImg}
                alt="wolfram"
              ></img>
            </a>
            <a
              href="https://www.sketch.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="sponsors-img-micro"
                style={{
                  width: "15%",
                }}
                src={sketchImg}
                alt="sketch"
              ></img>
            </a>
          </div>
        </div>

        <div className="sponsors-text">
          Interested in sponsoring Hack@Brown? Reach out to us on{" "}
          <a href="mailto:sponsor@hackatbrown.org">
            <b>sponsor@hackatbrown.org</b>
          </a>
          !
        </div>
        <div className="relative">
          <img src={sideFernsImg} alt="side ferns" className="sideferns"></img>
        </div>
      </div>
    );
  }
}
