import React, { Component } from 'react';
import axios from 'axios';
import Firebase from '../../../components/Firebase';
import { Modal, Button, Spinner, Row } from 'react-bootstrap';
import { stage_admin_ids, prod_admin_ids } from '../../../config/constants';

import '../AdminPanel.css';
// import { UserData } from './UserDummyData';
// import { HackerData } from './HackerDummyData';

/*
    define a state for the admin panel tables
*/
type AdminPanelState = {
    hackerData: {
        hackers: any[];
    };
    isAdmin: boolean;
    showAcceptanceModal: boolean;
    showResultsModal: boolean;
    showDetailedHackerModal: boolean;
    currentHacker: any;
    error: string;
    nameFilter: string;
    selectedFile: any;
};

type AdminPanelProps = {
    apiURL: string;
    firebase: Firebase | null;
    user: any;
};

const config = {
    // allow cors
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
    },
};

export default class AdminPanelDashboard extends React.Component<
    AdminPanelProps,
    AdminPanelState
> {
    // constructor
    constructor(props: AdminPanelProps) {
        super(props);
        this.state = {
            hackerData: {
                hackers: [],
            },
            isAdmin: undefined,
            showAcceptanceModal: false,
            showResultsModal: false,
            showDetailedHackerModal: false,
            currentHacker: null,
            error: '',
            nameFilter: '',
            selectedFile: null,
        };
    }

    componentDidMount() {
        // fetch data from the database
        const id = this.props.user.uid;
        const isUserAdmin =
            process.env.NODE_ENV === 'development'
                ? stage_admin_ids.includes(id)
                : prod_admin_ids.includes(id);

        if (isUserAdmin) {
            axios
                .get(
                    this.props.apiURL + '/hacker_account/get_hacker_accounts',
                    config
                )
                .then((res) => {
                    console.log('successfully fetched hacker data');
                    console.log(res.data);
                    this.setState({
                        hackerData: res.data,
                        isAdmin: true,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            this.setState({ isAdmin: false });
        }
    }

    render() {
        const setAcceptanceHandler = () => {
            if (process.env.NODE_ENV !== 'development') {
                // get file uploaded file input text file
                const file = this.state.selectedFile;

                if (!file || file.type !== 'text/plain') {
                    this.setState({
                        error: 'Invalid file selected',
                        showResultsModal: true,
                        showAcceptanceModal: false,
                    });
                    return;
                }

                // parse txt file to get array of emails

                const reader = new FileReader();

                reader.onload = (e) => {
                    const emails = e.target.result.toString().split('\n');

                    const formData = new FormData();
                    formData.append('hackers', emails.join(','));
                    // send array of emails to backend as POST
                    axios
                        .post(
                            this.props.apiURL +
                                '/hacker_registration/set_acceptances_admin',
                            formData,
                            config
                        )
                        .then((res) => {
                            console.log(res.data);
                            if (!res.data.success) {
                                this.setState({
                                    error: res.data.error,
                                    showResultsModal: true,
                                    showAcceptanceModal: false,
                                });
                            } else {
                                this.setState({
                                    showResultsModal: true,
                                    showAcceptanceModal: false,
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setState({
                                showResultsModal: true,
                                error: err,
                                showAcceptanceModal: false,
                            });
                        });
                };

                reader.readAsText(file);
            } else {
                console.log('set acceptance clicked on stage!');
                this.setState({
                    showResultsModal: true,
                    error: "Can't set acceptances on stage!",
                    showAcceptanceModal: false,
                });
            }
        };

        const downloadEmailsHandler = () => {
            const acceptedHackers = this.state.hackerData.hackers
                .filter((h) => {
                    return h.app_submitted;
                })
                .map((h) => {
                    return h.email;
                });

            const file = new Blob([acceptedHackers.join('\n')], {
                type: 'text/plain',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');

            link.href = fileURL;
            link.setAttribute('download', 'accepted_hackers.txt');

            document.body.appendChild(link);
            link.click();
        };

        const onFileChange = (e: any) => {
            const file = e.target.files[0];
            this.setState({ selectedFile: file });
        };

        return (
            <div className="centering-col">
                {/* <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Signup Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {UserData.map((item, index) => {
                            return (
                                <tr>
                                    <td>{index}</td>
                                    <td>{item.email}</td>
                                    <td>{item.signup_date}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table> */}
                {this.state.isAdmin ? (
                    <>
                        <h3 className="mt-1">Admin Actions</h3>

                        <div className="mt-2 mb-5 actions">
                            {/* <Button
                                variant="dark"
                                className="mx-1"
                                onClick={() =>
                                    this.setState({ showAcceptanceModal: true })
                                }
                            >
                                Set Acceptances (untested!)
                            </Button> */}

                            <Button
                                className="mx-1"
                                variant="dark"
                                onClick={downloadEmailsHandler}
                            >
                                Download All Application Submitted Emails
                            </Button>

                            <Button
                                className="mx-1"
                                variant="dark"
                                onClick={() =>
                                    this.setState({ showAcceptanceModal: true })
                                }
                            >
                                Set Acceptances from Emails List
                            </Button>
                        </div>

                        <Modal
                            show={this.state.showAcceptanceModal}
                            onHide={() =>
                                this.setState({ showAcceptanceModal: false })
                            }
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Set All Hacker Acceptances
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Upload a .txt file with all the emails of the
                                hackers you want to accept separated by
                                newlines!
                                <Row className="m-2">
                                    <input
                                        className="mx-1"
                                        type="file"
                                        onChange={onFileChange}
                                    />
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={setAcceptanceHandler}
                                >
                                    Set Acceptances
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={() =>
                                        this.setState({
                                            showAcceptanceModal: false,
                                        })
                                    }
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showDetailedHackerModal}
                            onHide={() =>
                                this.setState({
                                    showDetailedHackerModal: false,
                                })
                            }
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.state.currentHacker &&
                                        this.state.currentHacker
                                            .first_name}{' '}
                                    {this.state.currentHacker &&
                                        this.state.currentHacker.last_name}
                                    {!this.state.currentHacker && 'Hacker Info'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                This modal will show the detailed hacker account
                                info for <br />
                                <b>
                                    {this.state.currentHacker &&
                                        this.state.currentHacker.email}
                                </b>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="success"
                                    // onClick={setDetailedHackerHandler}
                                >
                                    Action Here
                                </Button>

                                <Button
                                    variant="danger"
                                    // onClick={setDetailedHackerHandler}
                                >
                                    Action Here
                                </Button>

                                <Button
                                    variant="secondary"
                                    onClick={() =>
                                        this.setState({
                                            showDetailedHackerModal: false,
                                        })
                                    }
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showResultsModal}
                            onHide={() =>
                                this.setState({ showResultsModal: false })
                            }
                        >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body className="py-4">
                                {this.state.error
                                    ? 'Error: ' + this.state.error
                                    : 'Success!'}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() =>
                                        this.setState({
                                            showResultsModal: false,
                                        })
                                    }
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <div className="divider"></div>

                        <h3 className="mt-4">Registrations</h3>

                        <div className="search-bar my-3">
                            <input
                                className="form-control search-input"
                                type="text"
                                placeholder="Filter Email/Name"
                                //value={this.state.currentPassword}
                                onChange={(e) =>
                                    this.setState({
                                        nameFilter: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <table className="overflow mb-5">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th>Signup Date</th>
                                    <th>Full Name</th>
                                    <th>School</th>
                                    <th>Application Submitted</th>
                                    <th>Accepted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.hackerData.hackers
                                    .filter((item) => {
                                        if (this.state.nameFilter !== '') {
                                            const fullname =
                                                item.first_name +
                                                ' ' +
                                                item.last_name;

                                            return (
                                                (fullname &&
                                                    fullname
                                                        .toLowerCase()
                                                        .indexOf(
                                                            this.state.nameFilter.toLowerCase()
                                                        ) > -1) ||
                                                (item.email &&
                                                    item.email
                                                        .toLowerCase()
                                                        .indexOf(
                                                            this.state.nameFilter.toLowerCase()
                                                        ) > -1)
                                            );
                                        } else {
                                            return true;
                                        }
                                    })
                                    .map((item, index) => {
                                        return (
                                            <tr
                                                className="hoverable"
                                                onClick={() => {
                                                    this.setState({
                                                        currentHacker: item,
                                                        showDetailedHackerModal:
                                                            true,
                                                    });
                                                }}
                                            >
                                                <td>{index}</td>
                                                <td>
                                                    <div className="school-width px-2">
                                                        {item.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="px-5">
                                                        {item.signup_date}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="school-width">
                                                        {item.first_name}{' '}
                                                        {item.last_name}{' '}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="school-width">
                                                        {item.school}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="med-width">
                                                        {item.app_submitted.toString()}
                                                    </div>
                                                </td>
                                                <td>
                                                    {item.accepted.toString()}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>

                        <div className="divider mb-5"></div>
                    </>
                ) : (
                    <>
                        {this.state.isAdmin === undefined ? (
                            <Spinner animation="border" />
                        ) : (
                            <h3>You are not authorized to view this page!</h3>
                        )}
                    </>
                )}
            </div>
        );
    }
}
