import app from 'firebase/app';
import 'firebase/auth';

type FirebaseConfig = {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
};

let config: FirebaseConfig;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev
    console.log('starting up firebase in stage mode');

    config = {
        apiKey: 'AIzaSyDLVIQWsqsO9l54Fvk9pHQFnNOD4HwyT1E',
        authDomain: 'hackatbrown2022-stage.firebaseapp.com',
        projectId: 'hackatbrown2022-stage',
        storageBucket: 'hackatbrown2022-stage.appspot.com',
        messagingSenderId: '75239898729',
        appId: '1:75239898729:web:f98e5b8e62d4a1cc90ecf8',
        databaseURL:
            'https://hackatbrown2022-stage-default-rtdb.firebaseio.com/',
        measurementId: '',
    };
    // console.log(config);
} else {
    // prod
    config = {
        // alternative is to move to .env
        apiKey: 'AIzaSyDFabPeZXMATF2bNPY_-lhfzAzF3F1ltj4',
        authDomain: 'hackatbrown2022.firebaseapp.com',
        projectId: 'hackatbrown2022',
        storageBucket: 'hackatbrown2022.appspot.com',
        messagingSenderId: '501449425931',
        databaseURL: 'https://hackatbrown2022-default-rtdb.firebaseio.com/',
        appId: '1:501449425931:web:5bb71835edaeb0ba07f30e',
        measurementId: '',
    };

    // for some reason the stage enviornment isn't working, so the prod firebase is the stage for right now
    // config = {
    //     apiKey: "AIzaSyDLVIQWsqsO9l54Fvk9pHQFnNOD4HwyT1E",
    //     authDomain: "hackatbrown2022-stage.firebaseapp.com",
    //     projectId: "hackatbrown2022-stage",
    //     storageBucket: "hackatbrown2022-stage.appspot.com",
    //     messagingSenderId: "75239898729",
    //     appId: "1:75239898729:web:f98e5b8e62d4a1cc90ecf8",
    //     databaseURL: "https://hackatbrown2022-stage-default-rtdb.firebaseio.com/",
    //     measurementId: ""
    // };
}

// export const storage = firebase.storage();

export default class Firebase {
    auth: app.auth.Auth;

    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
    }

    // Authentication API!
    doAuthListener = async (currPage: {
        setState: {
            (arg0: { user: app.User; userToken: string }): void;
            (arg0: { user: null; userToken: null }): void;
        };
    }) => {
        await this.auth.onAuthStateChanged(async (user) => {
            if (user) {
                await user.getIdToken().then(function (token: string) {
                    currPage.setState({
                        user: user,
                        userToken: token,
                    });
                    localStorage.setItem('user', user.uid);
                });
            } else {
                currPage.setState({
                    user: null,
                    userToken: null,
                });
                localStorage.removeItem('user');
            }
        });
    };

    doCreateUserWithEmailAndPassword = (email: string, password: string) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email: string, password: string) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doLogOut = () => this.auth.signOut();

    doPasswordReset = (email: string) =>
        this.auth.sendPasswordResetEmail(email);

    // doPasswordUpdate = (password: string) => {
    //   const user = this.auth.currentUser;
    //   if (user != null) {
    //     user.updatePassword(password);
    //   } // else, user is null
    // }
}
