import React from 'react';
import './faq.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import faqImg from '../../../../assets/images/LandingPage/cute_duckies.png';
import sideLeafImg from '../../../../assets/images/LandingPage/bigleaves.png';

type FAQprops = {};

type FAQstate = {
    state: string;
};

export default class FAQ extends React.Component<FAQprops, FAQstate> {
    constructor(props: FAQprops) {
        super(props);
        this.state = {
            state: 'good',
        };
    }
    render() {
        return (
            <div className="faq">
                <Container>
                    {/* <div className="faq-text"> */}
                    <div className="relative">
                        <img
                            alt="leaf 177"
                            className="sideleaf"
                            src={sideLeafImg}
                        ></img>
                    </div>
                    <Row>
                        <div className="faq-header">
                            <h1>FAQ</h1>
                        </div>
                    </Row>

                    <Row>
                        <Col sm={5}>
                            <Row>
                                <h4 className="faq-subtitle">
                                    Will the hackathon be in-person?
                                </h4>
                                <p>
                                    Based on the current guidelines at Brown, we do plan 
                                    on returning to an in-person event! However, this year, 
                                    we are unable to host participants who do not reside 
                                    in the Providence area. Students will still be able 
                                    to join us virtually, and we'll work hard to make 
                                    sure that all of our events such as sponsorship 
                                    1:1s and workshops are easily accessible. 
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    Will there still be hardware hacks?
                                </h4>
                                <p>
                                    Hardware will be available for in-person hackers
                                    in our hardware booth. Unfortunately, hardware supplies
                                    provided from Hack@Brown will not be available for our 
                                    virtual hackers but we encourage hardware hacks
                                    if you have access to your own supplies!
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    How do teams work?
                                </h4>
                                <p>
                                    You’re welcome to have up to four people on a team during the hackathon! 
                                    Don’t worry if you don’t have a team yet, we’ll have some fun team-forming 
                                    sessions and additional opportunities to find an awesome group of 
                                    people to work with.
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    Do we have to submit a project if we
                                    participate?
                                </h4>
                                <p>
                                    You are not required to submit a project to
                                    participate, but in order to be eligible for
                                    prizes and swag, you will need to submit to
                                    Devpost at the end of the hackathon!
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    I’m interested in sponsoring Hack@Brown -
                                    who can I contact?
                                </h4>
                                <p>
                                    We are so excited to have you join us! You
                                    can email{' '}
                                    <b>
                                        {' '}
                                        <a
                                            id="sponsor-email"
                                            href="mailto:sponsor@hackatbrown.org"
                                        >
                                            sponsor@hackatbrown.org
                                        </a>
                                    </b>{' '}
                                    and we will email you ASAP!
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    My question is not answered here!
                                </h4>
                                <p>
                                    We’re here for you! Send us an email at{' '}
                                    <b>
                                        {' '}
                                        <a
                                            id="hello-email"
                                            href="mailto:hello@hackatbrown.org"
                                        >
                                            hello@hackatbrown.org
                                        </a>
                                    </b>{' '}
                                    or reach out to us on Facebook, Twitter, and
                                    Instagram!
                                </p>
                            </Row>
                        </Col>
                        <Col sm={2}></Col>
                        <Col sm={5}>
                            <Row>
                                <h4 className="faq-subtitle">
                                    Who can attend?
                                </h4>
                                <p>
                                    All undergraduate and graduate students are
                                    welcome.
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    What events will be held?
                                </h4>
                                <p>
                                    More details regarding specific logistics will be coming 
                                    soon, but you can look forward to a variety of events 
                                    including panels, chats with our amazing sponsors, 
                                    keynote speakers, and workshops!
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    How will prizes work?
                                </h4>
                                <p>
                                    We will have a variety of sponsor prizes and
                                    hackathon specific prizes. For winners joining us 
                                    virtually, these prizes will be shipped directly to you!
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    How much is this going to cost me?
                                </h4>
                                <p>
                                    Hack@Brown is completely free, including
                                    merch, coffee chats with sponsors, great
                                    talks with tech leads, and an unforgettable
                                    experience!
                                </p>
                            </Row>
                            <Row>
                                <h4 className="faq-subtitle">
                                    Will there still be swag?
                                </h4>
                                <p>
                                    Swag will be provided to participants for pickup on Brown’s campus! 
                                    In order to be eligible, you must either attend a certain number of events or 
                                    workshops OR make a valid submission to Devpost. 
                                </p>
                            </Row>
                            <Row className="p-0 m-0">
                                <img id="img" src={faqImg} alt="duck img" />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
