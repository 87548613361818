import React, { Component } from 'react';
import AdminPanelDashboard from './components/AdminPanelDashboard';
import { Button } from 'react-bootstrap';

import './AdminPanel.css';
import Firebase from '../../components/Firebase';

const PASSWORD = 'WoodWatchers1764';

interface IState {
    currentPassword: String;
    verified: boolean;
    hasSubmit: boolean;
    user: any;
}

type AdminPanelProps = {
    apiURL: string;
    firebase: Firebase | null;
};

export default class AdminPanelHomePage extends React.Component<
    AdminPanelProps,
    IState
> {
    constructor(props: AdminPanelProps) {
        super(props);

        this.state = {
            currentPassword: '',
            verified: false,
            hasSubmit: false,
            user: null,
        };
    }

    componentDidMount() {
        // check if user is logged in or not
        let currFirebase = this.props.firebase;
        if (currFirebase == null) {
        } else {
            if (currFirebase != null) {
                currFirebase.doAuthListener(this);
            }
        }
    }

    handleSubmit(e: any) {
        e.preventDefault();

        if (this.state.currentPassword === PASSWORD) {
            this.setState({
                currentPassword: '',
                verified: true,
                hasSubmit: true,
            });
        }

        // // Prepare form data
        // var email_signup_data = new FormData();
        // const email = this.fixEmail(this.state.currentTask);
        // if (email === "ERROR") {
        //     this.setState({
        //         errorStatus: "Invalid email address: " + this.state.currentTask,
        //         lastEmail: this.state.currentTask,
        //         hasSubmit: true
        //     });
        // } else {
        //     email_signup_data.append("email", email);
        //     // Need headers
        //     const config = {
        //         headers: { "Content-Type": "application/x-www-form-urlencoded" }
        //     };
        //     // send post request
        //     axios
        //         .post(
        //             "https://api2020-hackatbrown.herokuapp.com/email_signup/register",
        //             email_signup_data,
        //             config
        //         )
        //         .then(res => {
        //             // set the error status message in state
        //             this.setState({
        //                 errorStatus: res.data.message,
        //                 lastEmail: res.data.email,
        //                 hasSubmit: true
        //             });
        //         });
        // }
    }

    render() {
        return (
            <div className="container">
                <h1 className="my-5">Hack @ Brown Admin Panel</h1>

                <div className="divider mb-5"></div>

                {!this.state.verified && (
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <input
                            className="form-control password-input"
                            type="password"
                            placeholder="Password"
                            //value={this.state.currentPassword}
                            onChange={(e) =>
                                this.setState({
                                    currentPassword: e.target.value,
                                })
                            }
                        />

                        <Button className="form-submit my-3" type="submit">
                            Enter!
                        </Button>
                    </form>
                )}
                {this.state.verified && (
                    <AdminPanelDashboard
                        apiURL={this.props.apiURL}
                        firebase={this.props.firebase}
                        user={this.state.user}
                    />
                )}

                <Button
                    className="back-button"
                    variant="link"
                    onClick={() => window.history.back()}
                >
                    ⬅ Back
                </Button>
            </div>
        );
    }
}
