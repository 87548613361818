import React from 'react';
import './MeetTheTeam.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MeetTheTeamImg from '../../../../../src/assets/images/LandingPage/meettheteam.jpg';
import sideLeafRight from '../../../../assets/images/LandingPage/bigleaves.png';

// import wateringCanImg from '../../../../assets/images/LandingPage/wateringcan 1.png';
// import sideLeaf2Img from '../../../../assets/images/LandingPage/sideleaf2.png';
// import sideFernsImg from '../../../../assets/images/LandingPage/sideferns.png';

type MeetTheTeamProps = {};

type MeetTheTeamState = {
    state: string;
};

export default class MeetTheTeam extends React.Component<
    MeetTheTeamProps,
    MeetTheTeamState
> {
    constructor(props: MeetTheTeamProps) {
        super(props);
        this.state = {
            state: 'good',
        };
    }
    render() {
        return (
            <div className="meetteam">
                <Container>
                    <Row className="align-items-center">
                        <Col sm={9} className="order-md-4">
                            <div className="body-hack">
                                <h1 className="title-hack">Meet The Team!</h1>
                                <img
                                    src={MeetTheTeamImg}
                                    alt="meet the team"
                                    className="team-img"
                                ></img>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className="pt-3 relative">
                    <img
                        src={sideLeafRight}
                        className="side-leaf-right"
                        alt="sideleafright"
                    ></img>
                    {/* <img
                        src={sideLeaf2Img}
                        alt="side leaf"
                        className="side-leaf"
                    ></img>
                    <img
                        src={wateringCanImg}
                        alt="watering can"
                        className="watering-can"
                    ></img>

                    <img
                        src={sideFernsImg}
                        alt="side fern"
                        className="side-ferns"
                    ></img> */}
                </div>
            </div>
        );
    }
}
