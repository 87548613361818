import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Itinerary.css';
import wormImg from '../../../../assets/images/LandingPage/worm.png';
import flowerImg from '../../../../assets/images/LandingPage/flower-2 2.png';

type ItineraryProps = {};

type ItineraryState = {
    state: string;
};

export default class Itinerary extends React.Component<
    ItineraryProps,
    ItineraryState
> {
    constructor(props: ItineraryProps) {
        super(props);
        this.state = {
            state: 'good',
        };
    }
    render() {
        return (
            <div className="itinerary">
                <div className="relative">
                    <img alt="flower" className="img-flower" src={flowerImg} />
                </div>

                <Container>
                    <Row>
                        <Col>
                            <h1 className="itinerary-title">Itinerary</h1>
                            <p>
                                Specific locations to be added closer to event!
                            </p>
                            <br></br>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} lg={6}>
                            <Row>
                                <Col>
                                    <h2 className="itinerary-header">
                                        Saturday
                                    </h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>9:00 AM - 10:00 AM</b>
                                    </p>
                                    <p>
                                        <b>Check-In</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>10:00 AM - 11:00 AM</b>
                                    </p>
                                    <p>
                                        <b>Virtual Sponsor Fair</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>11:00 AM - 12:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Kick-off</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>12:00 PM - 1:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Lunch & Team Forming</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>1:00 PM - 2:00 PM</b>
                                    </p>
                                    <p>
                                        <b>
                                            Intro to Python (Web Apps with
                                            Flask) Workshop
                                        </b>
                                    </p>
                                    <p>
                                        <b>Salomon 001</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>2:00 PM - 3:00 PM</b>
                                    </p>
                                    <p>
                                        <b>
                                            Increasing Accessibility in Tech
                                            Panel
                                        </b>
                                    </p>
                                    <p>
                                        <b>Virtual</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>3:00 PM - 4:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Sustainable Tech Panel</b>
                                    </p>
                                    <p>
                                        <b>Virtual</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>4:00 PM - 5:00 PM</b>
                                    </p>
                                    <p>
                                        <b>In-Person Sponsorship Fair</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>5:00 PM - 6:00 PM</b>
                                    </p>
                                    <p>
                                        <b>React Workshop</b>
                                    </p>
                                    <p>
                                        <b>Salomon 001</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>6:00 PM - 7:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Dinner</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>7:00 PM - 8:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Figma Workshop</b>
                                    </p>
                                    <p>
                                        <b>Salomon 001</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>8:00 PM - 9:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Design Thinking Workshop</b>
                                    </p>
                                    <p>
                                        <b>Friedman 102</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>9:00 PM - 10:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Trivia Tournament</b>
                                    </p>
                                    <p>
                                        <b>Sayles AUD</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>10:00 PM - 12:00 AM</b>
                                    </p>
                                    <p>
                                        <b>Game Night</b>
                                    </p>
                                    <p>
                                        <b>Sayles AUD</b>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6} lg={6}>
                            <Row>
                                <Col>
                                    <h2 className="itinerary-header">Sunday</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>10:00 AM - 11:00 AM</b>
                                    </p>
                                    <p>
                                        <b>Brunch</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>11:00 AM - 12:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Discord Bot Workshop</b>
                                    </p>
                                    <p>
                                        <b>Salomon 001</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>12:00 PM - 1:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Ethics in Tech Workshop</b>
                                    </p>
                                    <p>
                                        <b>Friedman 102</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>1:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Hacking Ends</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>1:30 PM</b>
                                    </p>
                                    <p>
                                        <b>Judging Begins</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>2:00 PM - 3:00 PM</b>
                                    </p>
                                    <p>
                                        <b>Navigating Careers Panel</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <b>3:30 PM - 4:30 PM</b>
                                    </p>
                                    <p>
                                        <b>Closing Ceremony</b>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <img alt="worm" className="img" src={wormImg} />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
