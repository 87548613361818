import React from 'react';
import './HackathonAbout.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import wateringCanImg from '../../../../assets/images/LandingPage/wateringcan 1.png';
import sideLeaf2Img from '../../../../assets/images/LandingPage/sideleaf2.png';
import sideFernsImg from '../../../../assets/images/LandingPage/sideferns.png';

type HackathonAboutProps = {};

type HackathonAboutState = {
    state: string;
};

export default class HackathonAbout extends React.Component<
    HackathonAboutProps,
    HackathonAboutState
> {
    constructor(props: HackathonAboutProps) {
        super(props);
        this.state = {
            state: 'good',
        };
    }
    render() {
        return (
            <div className="hack">
                <Container>
                    <Row className="align-items-center">
                        <Col sm={9} className="order-md-4">
                            <div className="body-hack">
                                <h1 className="title-hack">
                                    What is a Hackathon?
                                </h1>
                                <p className="body-text-hack">
                                    Hackathons are a unique opportunity for
                                    those interested in software, hardware, and
                                    design to collaborate in a sprint-like
                                    event, creating their own work and projects
                                    from scratch. Hackers will compete in teams
                                    to develop software and hardware solutions
                                    to real world problems they face, to show
                                    off cool concepts, or just to build things
                                    for fun! At the end of the hackathon, teams
                                    will have the chance to submit and present
                                    their work to judges, who will provide
                                    feedback and even award prizes based off
                                    certain criteria.
                                    <br />
                                    <br />
                                    Hack@Brown will be a 48-hour long hackathon
                                    for college students, not just from Brown
                                    but from all around, to come together and
                                    spend the weekend learning new skills and
                                    building exciting projects! This year, we
                                    will be having our first-ever design track,
                                    where participants can learn about the
                                    design process, from creating initial
                                    wireframes to eventually designing a
                                    high-fidelity mockup.
                                    <br />
                                    <br /> While hackathons are competitive in
                                    nature, we want Hack@Brown to be centered
                                    around learning as much as possible. We'll
                                    be hosting numerous workshops and talks
                                    during the event for everyone to have the
                                    opportunity to learn more about specific
                                    topics.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className="center-horiz pt-3 relative">
                    <img
                        src={sideLeaf2Img}
                        alt="side leaf"
                        className="side-leaf"
                    ></img>
                    <img
                        src={wateringCanImg}
                        alt="watering can"
                        className="watering-can"
                    ></img>

                    <img
                        src={sideFernsImg}
                        alt="side fern"
                        className="side-ferns"
                    ></img>
                </div>
            </div>
        );
    }
}
